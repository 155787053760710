import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import {
    RestaurantContext,
    RestaurantActions,
} from "../contexts/RestaurantContext";


import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";


interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}

const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: "flex",
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            "&$checked": {
                transform: "translateX(12px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: "none",
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    })
)(Switch);



export const Geocentricdatum = () => {
    const renderdata = useContext(RestaurantContext);

    const [state, setState] = React.useState({
        HK1980: true,

    });
    const handleChange_Geocentricdatum = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        renderdata.dispatch({
            type: RestaurantActions.UPDATE_GEOCENTRICDATUM,
            use_HK1980: state.HK1980,
        });
        // console.log("HK1980", state.HK1980);
    };

    return (

        <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>HK1980</Grid>
                <Grid item>
                    <AntSwitch
                        checked={state.HK1980}
                        onChange={handleChange_Geocentricdatum}
                        name="HK1980"
                    />
                </Grid>
                <Grid item>WGS84</Grid>
            </Grid>
        </Typography>
    );

};

export const GeocodeUsage = () => {
    const renderdata = useContext(RestaurantContext);

    const [state, setState] = React.useState({

        Used_to_Heatmap: true,
    });


    const handleChange_Heatmap = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        renderdata.dispatch({
            type: RestaurantActions.UPDATE_GEOCODEUSAGE,
            Used_to_Heatmap: state.Used_to_Heatmap,


        });
        // console.log("Used_to_Heatmap", state.Used_to_Heatmap);
    };

    return (

        <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>HeatMap</Grid>
                <Grid item>
                    <AntSwitch
                        checked={state.Used_to_Heatmap}
                        onChange={handleChange_Heatmap}
                        name="Used_to_Heatmap"
                    />
                </Grid>
                <Grid item>Marker</Grid>
            </Grid>
        </Typography>
    );

};
