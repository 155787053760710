import React from "react";
import { useState, createContext, useReducer, Dispatch } from "react";
import test from "../pages/test.json";
import HongKong from "../18-clean-added.json";



import purplePIN from "../image/purple.png";
import redPIN from "../image/red.png";
import bluePIN from "../image/blue.png";
import greenPIN from "../image/green.png";
import yellowPIN from "../image/yellow.png";
import pinkPIN from "../image/pink.png";
import darkbluePIN from "../image/darkblue.png";
import darkorgangePIN from "../image/darkorgange.png";
import lightedPIN from "../image/lighted.png";
import red2PIN from "../image/red2.png";
import yellow2PIN from "../image/yellow2.png";
import sliverPIN from "../image/sliver.png";
import lightbluePIN from "../image/light blue.png";
import lighted2PIN from "../image/lightred2.png";
import purple2PIN from "../image/purple2.png";
import lightgreenPIN from "../image/lightgreen.png";
import darkgreenPIN from "../image/darkgreen.png";
import greyPIN from "../image/grey.png";
import greenbluePIN from "../image/greenblue.png";
import darkblue2PIN from "../image/darkblue2.png";
import darkgreen2PIN from "../image/darkgreen2.png";



interface RestaurantState {
  //color
  purple: any;
  red: any;
  blue: any;
  green: any;
  yellow: any;
  pink: any;
  darkblue: any;
  darkorgange: any;
  lighted: any;
  red2: any;
  greenblue: any;
  darkgreen2: any;
  darkblue2: any;
  purple2: any;
  yellow2: any;
  lighted2: any;
  sliver: any;
  lightblue: any;
  lightgreen: any;
  darkgreen: any;
  grey: any;
  //variable or array
  isLoaded: any;
  loadError: any;
  //boolean
  selectedAllDistrict: any;
  use_HK1980: any;
  Used_to_Heatmap: any;
  //variable
  returnHKdata: any;
  zoom: any;
  //array

  //HeatMap
  selectedHeatMapData: any;
  radius: any;
  opacity: any;
  dissipating: any;
  maxIntensity: any;
  ///data
  HongKong: any;
  selectedlocation: any;
  selectedDistrict: any,
  selectedMarker: {
    metadata: {
      companyName: any;
      licenseNo: any;
      last_retrieved: any;
      expdate: any;
    };
    location: {
      address: any;
      addressForLATLONRetrieve: any;
      district: any;
      districtID: any;
      shoppingMall: any;
      shopNo: any;
    };
    geocode: {
      HK1980: {
        lat: any;
        lng: any;
      };
      WGS84: {
        lat: any;
        lng: any;
      };
    };
  };
}


const initialState: RestaurantState = {
  //color
  purple: purplePIN,
  red: redPIN,
  darkgreen2: darkgreen2PIN,
  darkblue2: darkblue2PIN,
  blue: bluePIN,
  green: greenPIN,
  yellow: yellowPIN,
  pink: pinkPIN,
  darkblue: darkbluePIN,
  darkorgange: darkorgangePIN,
  lighted: lightedPIN,
  red2: red2PIN,
  greenblue: greenbluePIN,
  purple2: purple2PIN,
  yellow2: yellow2PIN,
  lighted2: lighted2PIN,
  sliver: sliverPIN,
  lightblue: lightbluePIN,
  lightgreen: lightgreenPIN,
  darkgreen: darkgreenPIN,
  grey: greyPIN,
  //variable or array

  Used_to_Heatmap: false,
  isLoaded: true,
  loadError: false,
  HongKong: HongKong,

  use_HK1980: false,


  selectedAllDistrict: false,
  selectedlocation: [
    { value: 100, label: "All", color: null },
    { value: 11, label: "Eastern", color: redPIN },
    { value: 12, label: "Wan Chai", color: bluePIN },
    { value: 15, label: "Southern", color: greenPIN },
    { value: 17, label: "Islands", color: purplePIN },
    { value: 18, label: "Central/Western", color: yellowPIN },
    { value: 51, label: "Kwun Tong", color: pinkPIN },
    { value: 52, label: "Kowloon City", color: greenbluePIN },
    { value: 53, label: "Wong Tai Sin", color: darkbluePIN },
    { value: 61, label: "Yau Tsim ", color: darkorgangePIN },
    { value: 62, label: "Mong Kok", color: lightedPIN },
    { value: 63, label: "Sham Shui Po", color: red2PIN },
    { value: 91, label: "Kwai Tsing", color: greyPIN },
    { value: 92, label: "Tsuen Wan", color: purple2PIN },
    { value: 93, label: "Tuen Mun", color: darkgreen2PIN },
    { value: 94, label: "Yuen Long", color: lighted2PIN },
    { value: 95, label: "Tai Po", color: sliverPIN },
    { value: 96, label: "North", color: darkblue2PIN },
    { value: 97, label: "Sha Tin", color: lightgreenPIN },
    { value: 98, label: "Sai Kung", color: darkgreenPIN },
  ],
  selectedDistrict: [],
  returnHKdata: { lat: 22.289179, lng: 114.17371 },
  zoom: 13,

  ///heatmap
  selectedHeatMapData: [],
  radius: 8,
  opacity: 0.6,
  dissipating: true,
  maxIntensity: 10,
  ///marker

  selectedMarker: {
    metadata: {
      companyName: null,
      licenseNo: null,
      last_retrieved: null,
      expdate: null,
    },
    location: {
      address: null,
      addressForLATLONRetrieve: null,
      district: null,
      districtID: null,
      shoppingMall: "",
      shopNo: "",
    },
    geocode: {
      HK1980: {
        lat: null,
        lng: null,
      },
      WGS84: {
        lat: null,
        lng: null,
      },
    },
  },
};


export const RestaurantContext = createContext<{
  state: RestaurantState;
  dispatch: Dispatch<any>;
}>({ state: initialState, dispatch: () => { } });

RestaurantContext.displayName = "RestaurantContext";

export enum RestaurantActions {
  UPDATE_SELECTMARKER,
  UPDATE_GEOCENTRICDATUM,
  UPDATE_GEOCODEUSAGE,
  UPDATE_SELECTEDLOCATION_SELECTION_OPTION,
  UPDATE_SELECTEDLOCATION_CLEAR,
  UPDATE_SELECTEDLOCATION_ROVE_VALUE,
  RETURN_MAP_CENTER_TO_HK,
}


export const MainProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const [state, dispatch] = useReducer(
    (state: RestaurantState, action: any,) => {
      switch (action.type) {
        case RestaurantActions.UPDATE_SELECTMARKER:
          // console.log(action.selectedMarker.geocode.HK1980)
          return {
            ...state,
            selectedMarker: action.selectedMarker,
            returnHKdata: action.selectedMarker.geocode.WGS84,
            zoom: action.zoom,
          };
        case RestaurantActions.UPDATE_GEOCENTRICDATUM:
          return {
            ...state,
            use_HK1980: action.use_HK1980,
          };
        case RestaurantActions.UPDATE_GEOCODEUSAGE:
          // console.log(action.Used_to_Heatmap)
          return {
            ...state,
            Used_to_Heatmap: action.Used_to_Heatmap,
          };
        case RestaurantActions.UPDATE_SELECTEDLOCATION_SELECTION_OPTION:
          // console.log(action.selectedlocation)
          return {
            ...state,
            selectedDistrict: action.selectedlocation
          };
        case RestaurantActions.UPDATE_SELECTEDLOCATION_CLEAR:
          // console.log(action.selectedlocation)
          return {
            ...state,
            selectedDistrict: action.selectedlocation
          };
        case RestaurantActions.UPDATE_SELECTEDLOCATION_ROVE_VALUE:
          // console.log(action.selectedlocation)
          return {
            ...state,
            selectedDistrict: action.selectedlocation
          };
        case RestaurantActions.RETURN_MAP_CENTER_TO_HK:
          // console.log(action.returnData)
          return {
            ...state,
            returnHKdata: action.returnData,
            zoom: action.zoom,
          };
        default:
          return state;
      }
    },
    initialState
  );

  return (
    <RestaurantContext.Provider value={{ state, dispatch }}>
      {children}
    </RestaurantContext.Provider>
  );
};
