import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import {
  RestaurantContext,
  RestaurantActions,
} from "../contexts/RestaurantContext";

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { SimpleTable } from "./RenderRestaurantInfor";
import DistrictSelectBox from "./DistrictSelectBox";
import { Geocentricdatum, GeocodeUsage } from "./AntiSwitch";
import ReturnHKbutton from "./ReturnHKbutton";



export default () => {

  return (
    <InformationPanelContainer>
      < SimpleTable />
      <DistrictSelectBox />
      < Geocentricdatum />
      <GeocodeUsage />
      <ReturnHKbutton />
    </InformationPanelContainer >
  );
};

const InformationPanelContainer = styled.div`
  background-color: red;
  height: 97vh;
  @media (min-width: 1024px) {
    height: 97vh;
    flex: 0.7;
  }
  @media (min-width: 600px) {
    height: 97vh;
    flex: 0.3;
  }
  width: 90%;
`;

