import React, { useState, useEffect, useContext } from "react";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
    RestaurantContext,
} from "../contexts/RestaurantContext";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const UseStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);


export const SimpleTable = () => {

    const selectedMarkerData = useContext(RestaurantContext);
    const Classes = UseStyles();
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div className={Classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={Classes.heading}>Restaurant information </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div>
                            <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Typography>Meta data</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Company Name:{selectedMarkerData.state.selectedMarker.metadata.companyName}<br />
                                        License No:{selectedMarkerData.state.selectedMarker.metadata.licenseNo}<br />
                                        Last retrieved:{selectedMarkerData.state.selectedMarker.metadata.last_retrieved}<br />
                                        Expdate:{selectedMarkerData.state.selectedMarker.metadata.expdate}<br />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                    <Typography>location</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Address:{selectedMarkerData.state.selectedMarker.location.address}<br />

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                    <Typography>Location(detail)</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Address for LATLON Retrieve:{selectedMarkerData.state.selectedMarker.location.addressForLATLONRetrieve}<br />
                                        Shopping Mall:{selectedMarkerData.state.selectedMarker.location.shoppingMall === null ?
                                            "null"
                                            : selectedMarkerData.state.selectedMarker.location.shoppingMall}<br />
                                        ShopNo:{selectedMarkerData.state.selectedMarker.location.shopNo === null ?
                                            "null"
                                            : selectedMarkerData.state.selectedMarker.location.shopNo}< br />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                    <Typography>Geographic coordinates</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Latitude:{selectedMarkerData.state.use_HK1980 ?
                                            selectedMarkerData.state.selectedMarker.geocode.HK1980.lat
                                            : selectedMarkerData.state.selectedMarker.geocode.WGS84.lat
                                        } < br />
                                            Longitude:{selectedMarkerData.state.use_HK1980 ?
                                            selectedMarkerData.state.selectedMarker.geocode.HK1980.lng
                                            : selectedMarkerData.state.selectedMarker.geocode.WGS84.lng
                                        }
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                        </div>


                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}