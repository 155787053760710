import React, { useState } from "react";
import styled from "styled-components";

import { MainProvider } from "./contexts/RestaurantContext";

import RenderMap from "../src/pages/RenderMap";
import InformationPanel from "./pages/InformationPanel";

const RootContainer = () => {

  return (
    <>
      <RenderMap />
      <InformationPanel />
    </>
  );
};

export default () => {
  return (
    <MainProvider>
      <Container>
        <RootContainer />
      </Container>
    </MainProvider>
  );
};

const Container = styled.div`
   /* height:97vh;
   width:99%; */
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;
