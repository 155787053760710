import React, { useState, useRef, useEffect, useContext, createContext, useCallback } from "react";
import styled from "styled-components";
import {
  GoogleMap,
  useLoadScript,
  Marker,
} from "@react-google-maps/api";
import {
  RestaurantContext,
  RestaurantActions,
} from "../contexts/RestaurantContext";
import ClipLoader from "react-spinners/ClipLoader";



export default () => {

  const renderdata = useContext(RestaurantContext);
  const markerdatalist = renderdata.state.HongKong;
  const districtList = renderdata.state.selectedDistrict;
  const selectedlocation = renderdata.state.selectedlocation


  const selectAllDistrict = districtList.find(c => c.label === "All") === undefined ? false : true;
  // console.log(selectAllDistrict)
  const selectedDistrict = selectAllDistrict ? selectedlocation : districtList;


  const Spinner = () => {
    return (<ClipLoader
      size={150}
      color={"#123abc"}
    />)
  }




  const markerImage = renderdata.state.use_HK1980 ? {
    url: renderdata.state.red,
    anchor: new google.maps.Point(0, 0),
    origin: new google.maps.Point(5, 5),
    scaledSize: new google.maps.Size(5, 5),
    size: new google.maps.Size(5, 5),
  } : {
      url: renderdata.state.purple,
    }

  // console.log(renderdata.state.selectedlocation)



  const RenderDistrict = selectedDistrict.map((Districtlist, index) => {
    const RenderMarkerlist = markerdatalist.map((markerDatalist, index) => {
      // console.log(Districtlist.value)
      if (markerDatalist.location.districtID === Districtlist.value) {
        return (
          <>
            <Marker
              key={markerDatalist.metadata.licenseNo}
              position={renderdata.state.use_HK1980 ? {
                lat: markerDatalist.geocode.HK1980.lat, lng: markerDatalist.geocode.HK1980.lng
              } : {
                  lat: markerDatalist.geocode.WGS84.lat, lng: markerDatalist.geocode.WGS84.lng
                }
              }
              onClick={() => {
                renderdata.dispatch({
                  type: RestaurantActions.UPDATE_SELECTMARKER,
                  selectedMarker: markerDatalist,
                });
              }
              }

              icon={
                // {
                //   url: Districtlist.color,
                //   // anchor: new google.maps.Point(0, 0),
                //   // origin: new google.maps.Point(5, 5),
                //   // scaledSize: new google.maps.Size(5, 5),
                //   // size: new google.maps.Size(5, 5),
                // }
                Districtlist.color
              }

            />
          </>
        );
      }

    });
    return (<li key={index}>{RenderMarkerlist}</ li>)
  });




  return (<>
    {RenderDistrict}
  </>);
};

