import React, { useState, useEffect, useContext, createContext } from "react";
import styled from "styled-components";
import {
    GoogleMap,
    useLoadScript,
    Marker,
    HeatmapLayer,
    Data
} from "@react-google-maps/api";
import {
    RestaurantContext,
    RestaurantActions,
} from "../contexts/RestaurantContext";




export default () => {



    const renderdata = useContext(RestaurantContext);
    const markerdatalist = renderdata.state.HongKong;
    const districtList = renderdata.state.selectedDistrict;

    let selectedHeatMapdata = renderdata.state.selectedHeatMapData;


    //idenify is it selected all district?
    const selectAllDistrict = districtList.find(c => c.label === "All") === undefined ? false : true;

    const selectedlocation = renderdata.state.selectedlocation
    const selectedDistrict = selectAllDistrict ? selectedlocation : districtList;

    const RenderDistrict = selectedDistrict.map((Districtlist, index) => {
        const data = markerdatalist.filter(word => word.location.districtID === Districtlist.value);
        return (data)
    });



    const renderselectedheatmapdata = RenderDistrict.map((data, index) => {
        selectedHeatMapdata = selectedHeatMapdata.concat(data)
    })



    const RenderMarkerlist = selectedHeatMapdata.map((markerDatalist, index) => {
        // console.log(markerDatalist.location.districtID)
        // console.log(selectedDistrict.value)
        return (new google.maps.LatLng(renderdata.state.use_HK1980 ?
            markerDatalist.geocode.HK1980.lat : markerDatalist.geocode.WGS84.lat, renderdata.state.use_HK1980 ?
            markerDatalist.geocode.HK1980.lng : markerDatalist.geocode.WGS84.lng)
        );
    });
    // console.log(RenderMarkerlist)


    return (
        <>
            <HeatmapLayer
                data={RenderMarkerlist}
                options={{
                    data: RenderMarkerlist,
                    radius: renderdata.state.radius,
                    opacity: renderdata.state.opacity,
                    dissipating: renderdata.state.dissipating,
                    maxIntensity: renderdata.state.maxIntensity,
                }}
            />
        </>
    )


};



