import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import AsyncSelect from 'react-select/async';
import {
  RestaurantContext,
  RestaurantActions,
} from "../contexts/RestaurantContext";


export default () => {

  const renderdata = useContext(RestaurantContext)

  const distructLocation = renderdata.state.selectedlocation;

  const filterLocation = (inputValue: string) => {
    return distructLocation.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterLocation(inputValue));
      }, 1000);
    });

  const OnChange = (newValue: any, action: any) => {
    // console.log("NewValue", newValue)
    // console.log("action", action.action)
    if (action.action === "select-option") {
      renderdata.dispatch({
        type: RestaurantActions.UPDATE_SELECTEDLOCATION_SELECTION_OPTION,
        selectedlocation: newValue,
      });
    }
    else if (action.action === "clear") {
      renderdata.dispatch({
        type: RestaurantActions.UPDATE_SELECTEDLOCATION_CLEAR,
        selectedlocation: [],
      });
    }
    else if (action.action === "remove-value") {
      const remove_value = newValue == null ? [] : newValue;
      // console.log(remove_value)
      renderdata.dispatch({
        type: RestaurantActions.UPDATE_SELECTEDLOCATION_ROVE_VALUE,
        selectedlocation: remove_value,
      });
    }
  }


  return (
    <>
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        onChange={OnChange}
      />

    </>
  );
};
