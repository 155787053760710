import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import {
    RestaurantContext,
    RestaurantActions,
} from "../contexts/RestaurantContext";



export default () => {

    const renderdata = useContext(RestaurantContext);


    return (
        <button onClick={() => renderdata.dispatch({
            type: RestaurantActions.RETURN_MAP_CENTER_TO_HK, returnData: {
                lat: 22.379252,
                lng: 114.122430,
            }, zoom: 14

        })}>
            Hong Kong
        </button>
    );
}