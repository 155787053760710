import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { GoogleMap, LoadScript, InfoWindow, HeatmapLayer, useLoadScript, Marker } from "@react-google-maps/api";
import styled from "styled-components";
import RenderMarker from "./RenderMarker";
import { RestaurantContext } from "../contexts/RestaurantContext";
import ClipLoader from "react-spinners/ClipLoader";
import RenderHeatMap from "./RenderHeatMap";

export default () => {
  const Spinner = () => {
    return (<ClipLoader
      size={150}
      color={"#123abc"}
    />)
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA5AhQ_9HKPoeTnV-BnA_a3Wq_HvVmSUII",
    libraries: ["places", "visualization"],
  })


  const Rendermap = () => {
    const renderdata = useContext(RestaurantContext);
    const center = {
      lat: renderdata.state.returnHKdata.lat,
      lng: renderdata.state.returnHKdata.lng,
    };
    const mapRef = useRef<HTMLDivElement | any>(null);

    const onMapLoad = useCallback((map) => {
      mapRef.current = map;
      // console.log(mapRef.current)
    }, []);
    const mapContainerStyle = {
      height: "97vh",
    };


    const heatmapdata = [
      { location: new google.maps.LatLng(37.785, -122.441), weight: 0.5 },
      { location: new google.maps.LatLng(37.785, -122.441), weight: 0.5 },
      { location: new google.maps.LatLng(37.785, -122.441), weight: 0.5 },
      { location: new google.maps.LatLng(37.785, -122.441), weight: 0.5 },
      { location: new google.maps.LatLng(37.785, -122.441), weight: 0.5 },
    ]




    console.log(heatmapdata)

    const Geocode_use = () => {
      // console.log(renderdata.state.Used_to_Heatmap)
      const use = renderdata.state.Used_to_Heatmap
      return (
        use ?
          <RenderHeatMap />
          // < HeatmapLayer data={heatmapdata}
          // />
          : <RenderMarker />
      )
    }

    return (
      <>
        <RenderMapContainer >
          <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={renderdata.state.zoom}
            center={center}
            onLoad={onMapLoad}
          >
            <Geocode_use />
          </GoogleMap>

        </RenderMapContainer>
      </>
    );

  };
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }
  return isLoaded ? < Rendermap /> : <Spinner />;
};





const RenderMapContainer = styled.div`
  background-color: "white";
  height: 90vh;
  @media (min-width: 1024px) {
    height: 90vh;
    flex: 0.7;
  }
  width:90%;
`;

